<template>
  <div>
    <d-body-top-bar
      :title="$t('views.live-accesses.index.title')"
      :class="$store.getters['layout/getInnerWidth'] < 1332 ? 'mt-3' : ''"
    >
      <div
        slot="icons"
      >
        <d-button
          text="views.client.details.body.right.balance.history"
          class="d-btn-sm btn d-btn-default font-text-title mr-3"
          @on:button-click="$router.push({name: 'accesses-list'})"
        />
      </div>
    </d-body-top-bar>
    <div class="container-fluid">
      <b-row>
        <b-col
          v-for="(item, key) of items"
          :key="'customer-details-access' + key"
          :cols="cols"
        >
          <customer-details
            :item="item"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import CustomerDetails from "@views/accesses/CustomerDetails";
import {getAccessesHistory} from "@api/doinsport/services/enzocard/access-history.api";

export default {
  components: {CustomerDetails},
  data: () => ({
    items: [],
    display: false,
  }),
  computed: {
    cols() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width > 1332) {
        return '3';
      }
      if (width > 1000) {
        return '4';
      }
      if (width <= 991 && width > 420) {
        return '6';
      }
      if (width <= 720) {
        return '12';
      }
    },
  },
  methods: {
    loadAccessesHistory() {
      getAccessesHistory(this.$moment.utc().format('YYYY-MM-DDTHH:mm'))
        .then((response) => {
          this.items = response.data['hydra:member'];
        })
      ;
    }
  },
  created() {
    this.loadAccessesHistory();
  },
  mounted() {
    this.$bus.$on('load:recent-accesses', this.loadAccessesHistory);
  },
  beforeDestroy() {
    try {
      this.$bus.$off('load:recent-accesses', this.loadAccessesHistory);
    } catch (e) {
    }
  }
}
</script>
