<template>
  <b-card>
    <b-row class="d-flex mt-1 mb-2" no-gutters>
      <b-col
        align="left"
        class="mt-1"
      >
        <div class="time-badge">
          <span class="badge-text text-white text-center">
            {{ $toTimezoneCustomFormat({date: item.occurredAt, format: 'DD/MM/YYYY - HH:mm'}) }}
          </span>
        </div>
      </b-col>
      <b-col
        v-if="item.clubClientPaymentTokenOperations.length > 0"
        align="middle"
      >
        <div class="width-max-content subscription-badge">
          <span class="badge-text">
            {{
              item.clubClientPaymentTokenOperations[0].debit + ' X ' + item.clubClientPaymentTokenOperations[0].paymentToken.name
            }}
          </span>
        </div>
      </b-col>
      <b-col
        align="right"
        class="mt-1"
      >
          <span
            class="icon-eye-size d-icon-gray icofont icofont-eye-alt pointer"
            @click="goToCustomerDetails"
          />
      </b-col>
    </b-row>
    <b-row>
      <b-col align="middle">
        <div class="product-box">
          <div class="product-img">
            <template>
              <d-avatar
                :full-name="item.user.clubClient.fullName"
                :image="item.user.clubClient.identityPhoto === null ? '' : $filePath(item.user.clubClient.identityPhoto.contentUrl)"
                size="160"
                color="#DDDDDD"
                text-color="#4D4F5C"
                text-font-size="70"
              />
            </template>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col align="middle">
        <label class="firstname-customer"> {{ item.user.clubClient.fullName }} </label>
      </b-col>
    </b-row>

    <b-row no-gutters>
      <!--
      TODO Waiting for data
      <b-col align="middle">
        <label class="detail-customer">
          {{ $t('views.client.index.infos.subscriber') }}
        </label>
      </b-col>
      -->
      <b-col cols="12">
        <label class="badge-label">
          {{ $t('views.live-accesses.index.access-code') }} {{ item.accessCode }}
        </label>
      </b-col>

      <b-col cols="12">
        <label class="badge-label mr-2">
          {{ $t('views.client.details.body.left.enzocard-access.access') }} :
        </label>
        <label
          class="badge-label"
          :class="item.incidence === 'Granted' ? 'green-text' : 'red-text'"
        >
          {{
            item.incidence === 'Granted' ? $t('views.client.details.body.left.enzocard-access.granted') : $t('views.client.details.body.left.enzocard-access.not-granted')
          }}
        </label>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    goToCustomerDetails() {
      this.$router.push({name: 'customer_details', params: {id: this.item.user.clubClient.id}});
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_b-card.scss";
@import "@lazy/client/details/_client-profile.scss";

.icon-eye-size {
  font-size: 19px;
}

.subscription-badge {
  background-color: #B0DFB7;
  padding: 0.8vh;
  align-items: center;
  display: block;
  border-radius: 10px;
}

.time-badge {
  background-color: #16396e;
  border-radius: 10px;
  padding-left: 6px;
  padding-right: 6px;
  width: 116px;
  text-align: center;
}

.width-max-content {
  min-width: max-content;
}

.badge-text {
  font: normal normal 400 13px Avenir;
  letter-spacing: -0.47px;
  color: #070B31;
}

.badge-label {
  font: normal normal 700 13px Avenir;
  opacity: 1;
}


.green-text {
  color: #58c458;
}

.red-text {
  color: #ce2424;
}
</style>
